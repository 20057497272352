import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-rating',
  template: `
    <p>
      rating works!
    </p>
  `,
  styles: []
})
export class RatingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
